.App {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
}

.App-logo {
    height: 40vmin;
}

.App-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #282c34;
    min-height: 100vh;
    color: #fff;
    font-size: calc(10px + 2vmin);
}

.App-link {
    color: #09d3ac;
}

body {
    ::-webkit-scrollbar { width: 0 !important; }
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
}
/*#root {*/
/*  margin: 0;*/
/*  position: absolute;*/
/*  top: 0;*/
/*  bottom: 0;*/
/*  left: 0;*/
/*  right: 0;*/
/*  height: 100%;*/
/*  width: 100%;*/
/*}*/

//.btn-warning {
//    background-color: #f87d27;
//    color: white;
//}
.esri-popup__action-text{
    text-decoration: underline;
}

.esri-view-width-less-than-large .esri-popup__action-text {
    display: block !important;
}
