$footer-height: 185px;
$header-height: 65px;
$min-container-height: 400px;
$height-offset: 90px;

.map-container {
    display: block;
    top: 0;
    right: 50px;
    left: 255px;
    margin: -35px 40px 30px 30px;
    padding: 0;
    width: calc(95% - 260px);
    height: calc(100vh - #{$height-offset});
    min-height: $min-container-height;
    overflow: hidden;
    font-family: 'Verdana', sans-serif;
    font-size: small;
    font-style: normal;

    .buttons-block {
        float: left;
        margin-left: 10px;
        width: 190px;
        height: 300px;
        text-align: left;

        button {
            margin: 10px 0;
        }

        table {
            tr {
                text-align: left;
            }
        }
    }

    .map-block {
        border-width: 1px;
        border-style: solid;
        width: 100%;
        //height: calc(50vh);
        height: calc(100vh - #{$height-offset} - 70px);
    }
}

.container-overlay {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.9;
    z-index: 10;
    background-color: #fff;
    width: 100%;
    height: 100%;

    &.hide {
        display: none;
    }
}

.map-info {
    display: block;
    margin: 10px 0;
    width: 100%;
    height: 30px;

    &.hide {
        opacity: 0;
    }

    .element {
        display: inline-block;
        float: left;
        margin-right: 20px;
        line-height: 30px;
        font-size: 15px;
        &.hide {
            opacity: 0;
        }
    }

    .element-span {
        display: inline-block;
        width: auto;
        min-width: 30px;
        line-height: 30px;
    }

    .elevation-input {
        width: 60px;
        line-height: 20px;
    }

    .rt-button {
        float: left;
        margin-top: -5px;

        &.hide {
            opacity: 0;
        }
    }
}


