.ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.9);
}
.ReactModal__Overlay {

}

.ReactModal__Content {
    height: 210px;
    width: 400px;
    margin: auto;

    display: block;
    opacity: 1;
    z-index: 101;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #fff;

    .modal-container {
        position: relative;
        padding: 15px;

        .text {
            margin-top: 10px;
            text-align: center;
            color: #333;
            h3 {
                text-align: center;
            }
        }

        .buttons {
            padding: 35px 50px 20px 95px;

            button.btn-spacing {
                margin-left: 40px;
            }
        }
    }
}
