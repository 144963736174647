.leader {
    position: relative;
}

.leader__image { // sass-lint:disable-line no-empty-rulesets class-name-format
}

.leader__image img {  // sass-lint:disable-line class-name-format
    display: block;
    width: 100%;
}

.leader__panel { // sass-lint:disable-line class-name-format
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.leader__panel__row { // sass-lint:disable-line class-name-format
    padding: 1rem 0;
}

.leader__panel__row--white { // sass-lint:disable-line class-name-format
    margin-top: 8%;
    background-color: rgba(255, 255, 255, 0.6);
    padding: 2em;
    color: #453f3a;
}

.button-margin {
    margin: 2em 0;
}

.leader__panel__row--white p { // sass-lint:disable-line class-name-format
    max-width: 480px;
    font-size: 1.35em;
}

.container--home {
    right: 0;
    left: 0;
    margin: auto;
}

.container--home .row {
    padding-bottom: 2rem;
}

.container--home .col-md {
    padding: 2rem;
}

.container--home h3 {
    text-transform: uppercase;
}

.container--home p {
    font-size: 1.15em;
}

p {
    text-align: justify;
}

h3 {
    text-align: left;
}

.sign-up-button {
    float: left;
    margin-top: 15px;
}

.global--width {
    margin: auto;
    .row-top {
        right: 0;
        left: 0;
        margin: auto;
        padding: 1em 3em 5em 1em;
        max-width: 960px;
    }
}

.btn-warning, .btn-lg {
    background-color: #f87d27;
    color: #fff;
    &:hover, &:active, &:not(:disabled):not(.disabled):active {
        background-color: #ff8c00;
        color: #fff;
    }
}

