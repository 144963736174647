.mc-container-ext {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  &.hidden {
    display: none;
  }
}

.mc-container-background {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #eeeeee;
  opacity: 0.9;
}

