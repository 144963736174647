.top-nav {
    display: block;
    margin-bottom: 20px;
    border-bottom: 2px solid #eee;
    width: 100%;
    text-transform: uppercase;
    font-size: 16px;
    tr {
        td {
            padding: 20px 20px 20px 50px;
            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }

            &.active {
                color: #ff8c00;
                font-weight: bold;
                &:hover {
                    cursor: default;
                    text-decoration: none;
                }
            }
        }
    }
}

.new-item-container {
    padding: 10px 100px 30px 10px;
    width: 100%;
    text-align: right;
}

.dropdown-selector {
    margin: 10px 40px;
    max-width: 620px;
    &.wide {
        max-width: 1020px;
    }
}

.margin-left-20 {
    margin-left: 20px;
}

.table-header {
    margin: 10px 40px;
    text-align: left;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
    .input-group-prepend {
        display: inline-block;
        margin: 0 0 20px 30px;
        width: 400px;
        height: 20px;
        font-size: 16px;
    }
}

.new-item-container.client {
    &.hide {
        display: none;
    }
    display: block;
    float: right;
    .input-div {
        display: block;
        float: right;
        margin-bottom: 10px;
        width: 500px;
        span, input {
            display: inline-block;
        }

        input {
            margin-left: 10px;
            width: 400px;
        }
    }
}

.new-item-container.project {
    &.hide {
        display: none;
    }
    display: block;
    float: right;
    .input-div {
        display: block;
        span, input {
            display: inline-block;
        }

        input {
            margin-left: 10px;
            width: 400px;
        }

        button {
            margin-top: 10px;
        }
    }
}
