.footer {
    display: block;
    position: relative;
    background-image: url('/images/background--footer.png');
    background-size: cover;
    width: 100%;
}

.footer-inner {
    padding: 1.5em 1em 0.5em 0.5em;
}

p.copyright {
    opacity: 0.7;
    margin-left: 1em;
    color: #fff;
    font-size: 1.15em;
}

.fc-logo {
    display: inline-block;
    float: left;
    margin: 2px 0 0 15px;
    cursor: pointer;
    width: 200px;
    height: 27px;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
}

.fc-logo {
    display: block;
    background-image: url('/images/logo--fastcast.svg');
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 200px;
    height: 30px;
}

.fc-logo:hover {
    background-color: none;
}

.fc-topnav-bar {
    border-bottom: 2px solid #ffa500;
    min-height: 64px;
    .navbar-nav {
        .nav-item {
            cursor: pointer;
            padding: 11px 15px;
            &:hover {
                text-decoration: underline;
            }

            a {
                color: #282c34;
            }
            text-transform: uppercase;
            &.active {
                cursor: pointer;
                text-decoration: none;
                a {
                    cursor: default;
                    text-decoration: none;
                    color: #ff8c00;
                }
            }
        }
    }
}
