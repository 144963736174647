.contact {
    display: block;
    position: relative;
    margin: 50px auto 80px auto; // sass-lint:disable-line shorthand-values
    //min-height: calc(~'100vh - 378px');
    //min-height: calc(100% - 378px);
    padding: 0 25px;
    width: 100%;
    max-width: 1000px;
    height: auto;
    font-family: Open Sans, sans-serif;

    .header {
        margin: 0 auto 30px auto; // sass-lint:disable-line shorthand-values
        color: #f4801f;
    }

    form {
        .form-elem-group {
            margin-bottom: 1em;
        }

        label {
            display: block;
            text-align: left;
            line-height: 1.42857143;
            color: #666;
            font-size: 14px;
            font-weight: bold;
        }

        input, textarea {
            border: 1px solid #c6c2be;
            border-radius: 1px;
            padding: .5em;
            width: 100%;
            line-height: inherit;
            font-family: inherit;
            font-size: 16px;
            resize: none;
        }

        textarea {
            height: 160px;
        }
    }

    .light-text {
        color: #999;
    }
}
