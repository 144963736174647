/* NK: the following controls the styling of the massing configurator */

/* TODO: Given the 'mc-' naming prefix used, CSS Module approach is overkill.
This creates unique names in /dist/index.css that interferes with smooth debugging and installation
For now, this file needs to be copied to ../src/mass-config.css with each update
to match library README instructions, but this should be eliminated along with CSS Modules. */

.App {
  /* text-align: center; */
  background-image: '../public/fastcast_UI_placeholder.png';
}

/* the container for the massing configurator pop-up */

#mc-container {
  background-color: white;
  width: 850px !important;
  height: 563px;
  border-style: solid;
  border-color: #f87d27;
  border-width: 1px;
  border-radius: 4px;
  -webkit-box-shadow: 5px 10px 18px #444444;
  box-shadow: 5px 10px 18px #444444;
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

#mc-container-readonly {
  background-color: rgb(245, 245, 245);
  width: 850px !important;
  height: 563px;
  border-style: solid;
  border-color: #f87d27;
  border-width: 1px;
  border-radius: 4px;
  -webkit-box-shadow: 5px 10px 18px #444444;
  box-shadow: 5px 10px 18px #444444;
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* bar across top of pop-up */

#mc-header {
  width: 100%;
  padding: 10px;
  height: 40px;
  padding-top: 5px;
}

/* contains viewport */

#mc-left-column {
  width: calc(100% - 20px);
  width: 600px;
  margin: 10px;
}

#mc-viewport {
  /* background-image: url(https://www.accucities.com/wp-content/uploads/3D-Model-of-the-Canary-Wharf-London-with-Future-Skyline.jpg); */
  width: 600px;
  height: 500px;
  border-style: solid;
  border-color: #404040;
  border-width: 1px;
}

/* contains seg-list and scenario controls */

#mc-right-column {
  width: 220px;
}

/* buttons for fan and downloading DXF */

.btn-scenario-control {
  width: 220px;
  margin-top: 5px;
}

/* the box containing the mc-list-items */

#mc-seg-list {
  flex-direction: column;
  align-content: flex-start;
  height: 408px;
  padding-top: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  color: #404040;
}

/* the box containing the mc-seg-id and mc-seg-height */

#mc-seg-list-item {
  flex-direction: row;
  justify-content: flex-end;
  /* width: 90%; */
  height: 36px !important;
}

.mc-seg-id {
  margin-right: 10px;
  padding-top: 4px;
  width: 120px;
  /* height: 25px; */
  font-weight: 500;
  font-size: small;
  text-align: end;
}

#mc-seg-height {
  width: 55px;
  height: fit-content;
}

.btn-inc {
  background-color: lightgrey;
  border: 0px;
  padding: auto;
}

.mc-seg-height-value {
  background-color: white;
  width: 65px;
  text-justify: center;
  font-weight: 500;
  font-size: small;
  border-width: .5px;
}

.mc-seg-height-value-readonly {
  background-color: rgb(245, 245, 245);
  width: 65px;
  text-justify: center;
  font-weight: 400;
  font-size: small;
  border-width: .5px;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  margin-top: 2px;
  cursor: pointer;
}

.custom-file-upload-readonly {
  display: hidden;
  margin-top: 2px;
}

.hidden {
  display: none;
}

#fileNameTitle {
  font-weight: 400;
  font-size: 20px;
}

#filter-label {
  margin-right: 10px;
  font-style: italic;
  margin-bottom: 8px;
  font-size: 200 !important;
}

#filter-control {
  margin-left: 90px;
}