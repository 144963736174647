.load-spinner {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    z-index: 100;
    margin: auto;
    background-color: rgba(255, 255, 255, 0.5);
    width: 100%;
    height: 100%;
}

.hide {
    display: none;
}
