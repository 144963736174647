.sidebar-container {
    display: block;
    top: 0;
    left: 0;
    margin-bottom: 50px;
    border-right: 1px solid #f87d27;
    padding: 0 20px 0 20px;
    width: 320px;
    height: calc(100vh - 150px);
    min-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;

    .sidebar-element {
        display: block;
    }
}

.projects-header {
    margin: 0 0 20px 10px;
    text-align: left;
    font-size: 18px;
    font-weight: bold;
}

.sidebar-element {
    margin: 0 5px 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    padding: 10px 0 10px 0;
    text-align: left;
    line-height: 25px;
    color: #666;
    font-size: 17px;
    font-weight: bold;

    label.project-name {
        display: inline-block;
        position: relative;
        cursor: pointer;
        padding: 2px 0 0 27px;
        .indicator {
            display: inline-block;
            position: absolute;
            top: 2px;
            left: 2px;
            cursor: pointer;
            height: 35px;
            .collapsed.hidden {
                display: none;
            }

            .expanded.hidden {
                display: none;
            }
        }
    }

    .close{
        display: block;
        margin-top: 0;
        margin-right: 3px;
        padding-right: 10px;
        width: 24px;
        height: 24px;
    }

    &.active, &.new-project {
        border-color: #f87d27;
        cursor: default;
        color: #f87d27;
        font-weight: bold;
    }

    .add-link {
        cursor: pointer;
        color: #f87d27;
        font-weight: bold;

        &:hover {
            text-decoration: underline;
        }

        &.hide {
            display: none;
        }
    }

    &.new-project {
        margin-top: 10px;
        .add-link {
            font-size: 16px;
        }

        input.project-name {
            margin-left: 20px;
            border: 1px solid #ddd;
            border-radius: 5px;
            padding: 3px 5px 3px 7px;
            width: 200px;
            line-height: 23px;
            color: #555;
            font-size: 16px;
        }

        .link-block {
            display: block;
            cursor: default;
            width: 100%;

            .link{
                display: inline-block;
                right: 20px;
                margin: 8px 0 5px 172px;
                cursor: pointer;
                text-align: right;
                line-height: 24px;
                color: #f87d27;
                font-size: 15px;
                font-weight: bold;
                &:hover {
                    text-decoration: underline;
                }

                &.hide {
                    display: none;
                }
            }
        }
    }
}

.sidebar-sub-element {
    border-top: 1px solid #eee;
    cursor: pointer;
    padding: 5px 0 0 10px;
    color: #666;
    font-size: 15px;

    &.active {
        cursor: default;
        color: #f87d27;
        font-weight: bold;
    }

    .close {
        display: inline-block;
        margin: 10px 3px 0 0;
        padding-right: 13px;
        width: 24px;
        height: 24px;
        font-size: 15px;
    }

    &.add-link {
        cursor: pointer;
        color: #f87d27;
        font-weight: bold;
        display: none;

        &:hover {
            text-decoration: underline;
        }

        &.show {
            display: block;
        }
    }

    label {
        cursor: pointer;
        input {
            display: inline-block;
            margin-right: 10px;
        }
        &.disabled {
            cursor: default;
            opacity: 0.7;
        }
    }


}

.create-button {
    cursor: pointer;
    padding-left: 20px;
    color: #f87d27;
    font-size: 16px;
    font-weight: bold;
}

label.new-project {
    margin: 0 0 0 10px;
}

label.project-name, label.calculation-name {
    width: 190px;
    height: 30px;
    text-overflow: ellipsis;
    overflow-y: hidden;
    overflow-x: hidden;
}

label.calculation-name {
    width: calc(100% - 30px);
}

label.project-name {
    line-height: 24px;
    font-size: 16px;
    font-weight: 600;
}

label.calculation-name {
    line-height: 35px;
    font-size: 15px;
    font-weight: 500;
}

label.new-project, label.new-calculation {
    line-height: 24px;
    font-size: 15px;
    font-weight: 600;
}

.vertical-divider-20 {
    display: block;
    height: 30px;
    width: 100%;
}
