.app-container {
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 100vh;

    &.tools-screens {
        min-width: 620px;
    }

    &.admin-screens {
        min-width: 620px;
    }

    .component-container {
        display: block;
        width: 100%;
        min-height: calc(100vh - (65px + 74px));

        .iframe-style {
            border: 0;
            width: 100%;
            min-height: calc(100vh - (65px));
        }
    }
}
